import axiosInstance from '../../api/axiosInstance';


const state = () => ({
  /** @type IOrder[] */
  orders: [],
  /** @type IOrder|null */
  orderHistory: null,
  orderProducts: [],
  orderParentProducts: null,
  orderId: null,
  max: 50,
  /** @type IOrderHistoryItem[] */
  orderHistoryChanges: null,
});

const mutations = {
  SET_ORDERS_EMPTY(state) {
    state.orders = [];
  },
  SET_ORDERS(state, orders) {
    state.orders = Array.from(new Set(state.orders.concat(orders.map(item => Object.freeze(item)))));
  },
  SET_ORDER_HISTORY(state, orderHistory) {
    state.orderHistory = orderHistory;
    state.orderProducts = orderHistory.OrderProducts;
    state.orderParentProducts = orderHistory.parent ? orderHistory.parent.OrderProducts : undefined;
  },
  SET_ORDER_ID(state, orderId) {
    state.orderId = orderId.orderId;
  },
  SET_ORDER_HISTORY_CHANGES(state, orderHistoryChanges) {
    state.orderHistoryChanges = orderHistoryChanges;
  },
};
const actions = {
  PostCreateOrder({ commit }, {
    basketId, address, orgId, reserve, comment, shippingDate, cityId, deliveryType,
    useStockBalanceInsteadOfQuantity = undefined, isFastDelivery = false,
    isExpressDelivery = false,
    contactId = undefined, contractId = undefined,
  }) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: '/api/orders/createOrder',
        method: 'post',
        data: {
          basketId,
          address,
          orgId,
          reserve,
          comment,
          shippingDate,
          cityId,
          deliveryType,
          withCrosses: true,
          useStockBalanceInsteadOfQuantity,
          isFastDelivery,
          isExpressDelivery,
          contactId,
          contractId,
        },
      })
        .then((obj) => {
          const { orderId } = obj;
          commit('SET_ORDER_ID', { orderId });
          resolve(obj);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async GetOrders({ state, commit }, {
    fromDate, toDate, offset, accountId, orderState, query
  }) {
    return await axiosInstance({
      url: '/api/orders',
      params: {
        fromDate: fromDate ?? undefined,
        toDate: toDate ?? undefined,
        max: state.max,
        offset: offset ?? state.orders.length,
        accountId: accountId ?? undefined,
        orderState: orderState ?? undefined,
        q: query ?? undefined,
      }
    })
      .then((orders) => {
        commit('SET_ORDERS', orders);
        return orders;
      });
  },
  /**
   * GetOrderHistory получает товары заказа, по id
   * @param {any} _
   * @param data
   * @param {number} data.orderId
   * @param {number} data.userId
   * @return {Promise<unknown>}
   * @constructor
   */
  GetOrderHistory({ commit }, { orderId, userId }) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: '/api/orders/history',
        params: {
          orderId,
          userIdInOrder: userId ?? undefined,
        },
      })
        .then((orderHistory) => {
          commit('SET_ORDER_HISTORY', orderHistory);
          resolve(orderHistory);
        })
        .catch(err => reject(err));
    });
  },
  /**
   * GetOrder получает заказ
   * @param {any} _
   * @param {number} orderId
   * @return {Promise<unknown>}
   * @constructor
   */
  GetOrder(_, orderId) {
    return new Promise((resolve, reject) => {
      axiosInstance(`/api/orders/orderWithProducts/${orderId}`)
        .then((orderData) => {
          if (orderData.constructor === Object && Object.keys(orderData).length === 0) {
            return reject();
          }
          return resolve(orderData);
        })
        .catch(err => reject(err));
    });
  },
  /**
   * GetOrderSearchSuggestions получить поисковые подсказки для каталога по указанной строке
   * @param {any} _
   * @param {string} query
   * @return {Promise<unknown>}
   * @constructor
   */
  GetOrderSearchSuggestions(_, query = '') {
    return axiosInstance({
      url: '/api/search',
      params: {
        q: query.toLowerCase(),
      },
    });
  },
  /**
   * PostRepeatOrder повторяет заказ создавая новую корзину
   * @param _
   * @param {number} orderId
   * @param {number[]} exclude
   * @return {Promise<unknown>}
   */
  PostRepeatOrder(_, {orderId, exclude = []}) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: '/api/orders/repeatOrder',
        method: 'post',
        data: {
          orderId,
          exclude,
        },
      })
        .then((obj) => {
          const { basketId } = obj;
          resolve(basketId);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  onDeliveryDatesChanges({ commit }, data) {
    console.log('onDeliveryDatesChanges', data);
    commit('SET_ORDER_HISTORY_CHANGES', data);
  }
};

const getters = { };

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
