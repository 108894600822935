<template>
  <div>
    <div class="documents-page">
      <page-header class="header">
        Документы
        <template #rightArea>
          <el-button
            icon="el-icon-document-add"
            type="primary"
            size="small"
            @click="createHandler"
          >
            Запросить документ
          </el-button>
        </template>
      </page-header>

      <div class="filters">
        <label style="display: flex; align-items: center; gap: 5px">
          Тип документа:
          <el-select
            v-model="selectedType"
            :loading="typesLoading"
            size="small"
            @change="loadDocuments"
          >
            <el-option
              v-for="item in Object.keys(types)"
              :key="item"
              :label="types[item].name"
              :value="item"
            />
          </el-select>
        </label>
        <div style="display: flex; align-items: center; gap: 5px">
          <el-select
            :value="sortField"
            size="small"
            @change="sortChangeHandler"
          >
            <el-option
              label="По времени"
              value="createdAt"
            />
            <el-option
              label="По статусу"
              value="status"
            />
          </el-select>
          <el-button
            size="small"
            :icon="
              sortOrder === 'asc' ? 'el-icon-sort-down' : 'el-icon-sort-up'
            "
            @click="toggleOrder"
          />
        </div>
      </div>

      <div
        v-if="!loading && documents.length > 0"
        class="documents"
      >
        <Document
          v-for="document in documents"
          :key="document.uuid"
          :document="document"
        />
      </div>
      <div
        v-if="loading"
        class="documents"
      >
        <el-skeleton>
          <template #template>
            <el-skeleton-item
              v-for="i in 3"
              :key="i"
              variant="rect"
              style="height: 64px"
            />
          </template>
        </el-skeleton>
      </div>
      <ElEmpty
        v-if="!loading && !documents.length"
        description="Нет документов"
      />
    </div>
    <el-dialog
      v-if="showCreateModal"
      title="Запросить документ"
      :visible="true"
      append-to-body
      width="400px"
      @close="showCreateModal = false"
    >
      <el-select
        v-model="typeToCreate"
        style="width: 100%"
        placeholder="Тип документа"
      >
        <el-option
          v-for="item in Object.keys(types)"
          :key="item"
          :label="types[item].name"
          :value="item"
        />
      </el-select>
      <el-select
        v-if="typeToCreate === 'paymentInvoice'"
        v-model="orderId"
        style="width: 100%; margin-top: 20px"
        placeholder="Заказ"
      >
        <el-option
          v-for="order in orders"
          :key="order.id"
          :label="'№' + order.id + ' от ' + formatDate(order.createdAt)"
          :value="order.id"
        />
      </el-select>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          :disabled="!typeToCreate"
          :loading="creating"
          @click="create"
        >Создать</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import formatDate from 'utils/formatDate.js'
import { mapActions, mapMutations, mapState } from "vuex";

import PageHeader from "@/components/page-header/page-header.vue";
import Document from "@/views/admin/components/Document.vue";

export default {
  name: "Documents",
  components: { Document, PageHeader },
  metaInfo: {
    title: "Документы",
  },
  data() {
    return {
      typesLoading: false,
      showCreateModal: false,
      typeToCreate: null,
      creating: false,
      orderId: undefined,
    };
  },
  computed: {
    ...mapState("documents", ["documents", "types", "type", "loading", "sortField", "sortOrder"]),
    ...mapState("order", ["orders"]),
    selectedType: {
      get() {
        return this.type;
      },
      set(val) {
        this.SET_TYPE(val);
      },
    },
  },
  mounted() {
    this.typesLoading = true;
    this.getDocumentsTypes()
      .catch((err) => {
        this.$message.error("Не удалось загрузить типы документов");
        console.error("Ошибка загрузки типов документов", err);
      })
      .finally(() => {
        this.typesLoading = false;
      });
    this.loadDocuments();
    this.GetOrders({});
  },
  methods: {
    ...mapActions("documents", ["getDocuments", "getDocumentsTypes", "createDocument"]),
    ...mapActions("order", ["GetOrders"]),
    ...mapMutations("documents", ["SET_TYPE", 'SET_SORT_FIELD', 'SET_SORT_ORDER']),
    formatDate,
    async loadDocuments() {
      this.getDocuments().catch((err) => {
        this.$message.error("Не удалось загрузить документы");
        console.error("Ошибка загрузки документов", err);
      });
    },
    createHandler() {
      this.showCreateModal = true;
    },
    async create() {
      this.showCreateModal = false;
      this.creating = true;
      await this.createDocument({ type: this.typeToCreate, orderId: this.orderId})
        .then(() => {
          this.SET_TYPE(this.typeToCreate);
          this.typeToCreate = null;
          this.$message.success('Запрос на документ отправлен')
          this.getDocuments()
        }).catch((err) => {
          this.$message.error("Не удалось запросить документ");
          console.error("Ошибка запроса документа", err);
        }).finally(() => {
          this.creating = false;
        });
    },
    sortChangeHandler(sortField) {
      this.SET_SORT_FIELD(sortField);
      this.getDocuments()
    },
    toggleOrder() {
      this.SET_SORT_ORDER(this.sortOrder === 'asc' ? 'desc' : 'asc');
      this.getDocuments()
    }
  },
};
</script>

<style lang="sass" scoped>
.documents-page
  max-width: 800px
  margin-left: auto
  margin-right: auto
  padding-bottom: 40px
  padding: 20px 20px 0
  width: 100%

.header
  margin-bottom: 20px

.filters
  margin-bottom: 20px
  display: flex
  justify-content: space-between
  align-items: center

.documents
  display: flex
  flex-direction: column
  gap: 10px
</style>
