import axiosInstance from "api/axiosInstance";

export default {
  namespaced: true,
  state: () => ({
    isLoading: false,
    consignees: [],
    total: 0,
  }),
  mutations: {
    SET_IS_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
    SET_CONSIGNEES(state, consigneeList) {
      state.consignees = consigneeList;
    },
  },
  actions: {
    /**
     * Получить все записи
     * @param commit
     * @param orgId
     * @returns {Promise}
     */
    async getAllConsignees({ commit }, orgId = undefined) {
      commit("SET_IS_LOADING", true);
      return await axiosInstance({
        url: "/api/contactsConsignees/search",
        params: { orgId: orgId || undefined },
      })
        .then((response) => {
          commit("SET_CONSIGNEES", response);
          return response;
        })
        .finally(() => {
          commit("SET_IS_LOADING", false);
        });
    },

    /**
     * Добавить запись
     * @param _
     * @param {Object} data - Данные для обновления
     * @returns {Promise}
     */
    async createConsignee(_, data) {
      return axiosInstance({
        url: `/api/contactsConsignees`,
        method: "post",
        data,
      });
    },

    /**
     * Обновить запись
     * @param _
     * @param {number} id - ID записи
     * @param {Object} data - Данные для обновления
     * @returns {Promise}
     */
    async updateConsignee(_, { id, data }) {
      return axiosInstance({
        url: `/api/contactsConsignees/${id}`,
        method: "put",
        data,
      });
    },

    /**
     * Удалить запись
     * @param _
     * @param {number} id - ID записи
     * @returns {Promise}
     */
    async deleteConsignee(_, id) {
      return axiosInstance({
        url: `/api/contactsConsignees/${id}`,
        method: "delete",
      });
    },

    /**
     * Поиск записей
     * @param commit
     * @param {string} search - Поисковый запрос
     * @param orgId
     * @returns {Promise}
     */
    async searchConsignees({ commit }, { search, orgId }) {
      commit("SET_IS_LOADING", true);
      return await axiosInstance({
        url: "/api/contactsConsignees/search",
        params: { search, orgId },
      })
        .then((response) => {
          commit("SET_CONSIGNEES", response);
          return response;
        })
        .finally(() => {
          commit("SET_IS_LOADING", false);
        });
    },
  },
};
