<template>
  <div :class="b()">
    <n-table
      v-if="windowSize.width > 800"
      :class="b('table')"
      :data="basketItems"
      :columns="[
        { name: '', prop: 'index', width: '35px' },
        { name: 'Артикул', prop: 'article', width: '160px' },
        { name: 'Название', prop: 'name', width: 'minmax(100px, 1fr)' },
        { name: 'Цена', prop: 'price', width: '80px', align: 'right' },
        { name: 'К.', prop: 'minShippingRate', width: '100px', align: 'center' },
        { name: 'Склад', prop: 'storage', width: '50px', align: 'center' },
        { name: 'Кол-во', prop: 'quantity', width: '93px', align: 'center' },
        { name: 'Сумма', prop: 'sum', width: '120px', align: 'right' },
        { name: '', prop: 'delete', width: '65px', align: 'center' },
      ]"
      :row-class="rowClass"
      empty-text="Нет данных"
    >
      <template #index="scope">
        {{ scope.index + 1 }}
        <template v-if="productsWithoutActive.includes(scope.row.productId)">
          <el-tooltip
            effect="dark"
            content="Товара нет в наличии"
          >
            <i class="el-icon el-icon-info" />
          </el-tooltip>
        </template>
        <template v-if="productsOverStock.includes(scope.row.productId)">
          <el-tooltip
            effect="dark"
            content="Товара недостаточно"
          >
            <i class="el-icon el-icon-info" />
          </el-tooltip>
        </template>
      </template>
      <template #name="{ row }">
        <div style="display: flex; gap:10px;align-items: center;position: relative;flex-grow: 1">
          <el-image
            v-if="displayCatalogImages"
            :src="`${urlSrc + row.externalId}.JPEG`"
            :preview-src-list="[`${urlSrc + row.externalId}.JPEG`]"
            class="product-table__image"
            fit="cover"
            lazy
          >
            <template #error>
              <span class="image-slot" />
            </template>
          </el-image>
          {{ row.name }}
          <product-row-buttons
            :row="row"
            :basket-id="basketId"
            style="position: absolute;right: 0"
          />
        </div>
      </template>
      <template #price="scope">
        <price-output :price="normalizePrice(getPrice(scope.row))" />
      </template>
      <template #storage="{row}">
        <template v-if="row.supplier">
          {{ supplierCodeName(JSON.parse(row.supplier).supplier) }}
        </template>
      </template>
      <template #quantity="scope">
        <el-popover
          :value="errorsByProductId[scope.row.productId || scope.row.basketItemId] !== undefined && errorsByProductId[scope.row.productId || scope.row.basketItemId].available !== undefined"
          placement="top-start"
          width="200"
          trigger="manual"
          :content="
            errorsByProductId[scope.row.productId || scope.row.basketItemId] && errorsByProductId[scope.row.productId || scope.row.basketItemId].available !== undefined
              ? 'В наличии ' + errorsByProductId[scope.row.productId || scope.row.basketItemId].available
              : null
          "
        >
          <template #reference>
            <n-input-number
              v-model="scope.row.quantity"
              :min="calcStep(scope.row)"
              :max="(errorsByProductId[scope.row.productId || scope.row.basketItemId] && errorsByProductId[scope.row.productId || scope.row.basketItemId].available) || scope.row.stockBalance - (scope.row.stockBalance % calcStep(scope.row)) || Infinity"
              :step="calcStep(scope.row)"
              :disabled="productsWithoutActive.includes(scope.row.productId)"
              @change="$emit('changeQuantity', scope.row)"
            />
          </template>
        </el-popover>
      </template>
      <template #sum="scope">
        <price-output :price="normalizePrice(scope.row.quantity * getPrice(scope.row))" />
      </template>
      <template #delete="scope">
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          @click="$emit('deleteProduct', scope.row.basketItemId || scope.row.id)"
        />
      </template>
    </n-table>
    <div v-else>
      <div
        v-for="(item, i) in basketItems"
        :key="`product---${i}`"
        class="small-product"
      >
        <el-image
          v-if="displayCatalogImages"
          :src="`${urlSrc + item.externalId}.JPEG`"
          :preview-src-list="[`${urlSrc + item.externalId}.JPEG`]"
          class="product-table__image"
          fit="cover"
          lazy
        >
          <template #error>
            <span class="image-slot" />
          </template>
        </el-image>
        <h2>
          {{ item.name }}
          <el-button
            type="danger"
            icon="el-icon-delete"
            size="mini"
            @click="$emit('deleteProduct', item.basketItemId)"
          />
        </h2>
        <div class="small-product__info">
          <div>
            <h3>Артикул</h3>
            <p>{{ item.article }}</p>
          </div>
          <div>
            <h3>Цена</h3>
            <p><priceOutput :price="normalizePrice(getPrice(item))" /></p>
          </div>
          <div>
            <n-input-number
              v-model="item.quantity"
              :min="calcStep(item)"
              :max="item.stockBalance - (item.stockBalance % calcStep(item)) || Infinity"
              :step="calcStep(item)"
              :disabled="productsWithoutActive.includes(item.productId)"
              @change="$emit('changeQuantity', item)"
            />
          </div>
          <div>
            <h3>Сумма</h3>
            <p><priceOutput :price="normalizePrice(item.quantity * getPrice(item))" /></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import ProductRowButtons from '@/components/ProductRowButtons.vue';
import { getPrice,normalizePrice } from '@/utils/normalizePrice.js';
import supplierCodeName from '@/utils/supplierCodeName.js';

export default {
  name: 'OrderCreateBasketItems',
  components: { ProductRowButtons },
  props: {
    basketId: {
      type: Number,
      required: true
    },
    basketItems: {
      type: Array,
      required: true
    },
    productsWithoutActive: {
      type: Array,
      required: true
    },
    productsOverStock: {
      type: Array,
      required: true
    },
    productWithError: {
      type: Number,
      required: false,
      default: null
    },
    productsWithErrors: {
      type: Array,
      required: false,
      default: null
    },
  },
  data: () => ({
    urlSrc: import.meta.env.VUE_APP_IMG_URL,
  }),
  computed: {
    ...mapState('ui', ['userCurrency', 'windowSize', 'displayCatalogImages']),
    ...mapGetters('auth', ['_role']),
    ...mapGetters('basket', ['isFastDeliveryAvailable', 'isExpressDeliveryAvailable']),
    errorsByProductId() {
      return this.productsWithErrors.reduce(
        (previousValue, currentValue) => ({ ...previousValue, [currentValue.id]: currentValue }),
        {}
      );
    }
  },
  methods: {
    supplierCodeName,
    rowClass(row) {
      return {
        productsWithoutActive: this.productsWithoutActive.includes(row.productId),
        productsOverStock: this.productsOverStock.includes(row.productId),
        productWithError: row.basketItemId === this.productWithError || (
          this.productsWithErrors && this.productsWithErrors.find(error => error.id === (row.productId || row.basketItemId))
        ),
        fastDelivery: this.isFastDeliveryAvailable && row.isFastDelivery,
        expressDelivery: this.isExpressDeliveryAvailable && row.isExpressDelivery,
      };
    },
    calcStep: (row) => +row.minShippingRate || 1,
    normalizePrice,
    getPrice
  }
};
</script>

<style scoped lang="sass">
.order-create-basket-items
  overflow: auto
  display: flex
  flex-flow: column
  justify-content: flex-end
  margin: 5px 0 10px 0

.small-product
  h2
    font-size: 12px
    margin: 5px 0
    display: flex
    align-items: center
    justify-content: space-between
  h3
    font-size: 11px
    color: #636363
    font-weight: 400
    margin: 0
  p
    font-size: 11px
    color: #282828
    margin: 2px 0 0
  &:not(:last-child)
    margin-bottom: 10px
  &__info
    display: flex
    flex-flow: nowrap
    justify-content: space-between
</style>
