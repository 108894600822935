<template>
  <div class="personal">
    <page-header>
      Статистика
    </page-header>
    <div>
      <el-row
        v-if="healthcheck"
        v-loading="healthcheckLoading"
        :gutter="20"
      >
        <el-col :span="4">
          <div>
            <el-statistic
              :value="healthcheck.redisResponse"
              title="Ответ от Redis"
            >
              <template slot="prefix">
                <i
                  v-if="healthcheck.redisResponse"
                  class="el-icon-success"
                  style="color: green"
                />
                <i
                  v-else
                  class="el-icon-error"
                  style="color: red"
                />
              </template>
            </el-statistic>
          </div>
        </el-col>
        <el-col :span="4">
          <div>
            <el-statistic
              :value="healthcheck.product ? 'OK' : 'Error'"
              title="Состояние БД"
            >
              <template slot="prefix">
                <i
                  v-if="healthcheck.product"
                  class="el-icon-success"
                  style="color: green"
                />
                <i
                  v-else
                  class="el-icon-error"
                  style="color: red"
                />
              </template>
            </el-statistic>
          </div>
        </el-col>
        <el-col :span="4">
          <div>
            <el-statistic
              :value="healthcheck.order || 0"
              title="Количество заказов в очереди на обработку для 1с"
            />
          </div>
        </el-col>
        <el-col :span="4">
          <div>
            <el-statistic
              :value="Number(healthcheck.checkProblemInOrdersCount || 0)"
              title="Заказов с ошибками"
            />
          </div>
        </el-col>
        <el-col :span="4">
          <div>
            <el-statistic
              :value="healthcheck.usersOnline || 0"
              title="Пользователей онлайн"
            />
          </div>
        </el-col>
      </el-row>

      <h2>Статистика рекомендаций</h2>
      <el-form>
        <el-form-item label="Начиная с">
          <el-date-picker
            v-model="infoRequestsDate"
            :clearable="false"
            value-format="yyyy-MM-dd"
            format="dd MMM yyyy"
            size="small"
            @change="getInfo"
          />
        </el-form-item>
      </el-form>
      <el-row
        v-if="infoRequests"
        v-loading="infoRequestsLoading"
        :gutter="20"
        style="margin-top: 20px"
      >
        <el-col :span="4">
          <div>
            <el-statistic
              :value="Number(infoRequests.accountCount || 0)"
              title="Клиентов, сделавших заказы"
            />
          </div>
        </el-col>
        <el-col :span="4">
          <div>
            <el-statistic
              :value="Number(infoRequests.userCount || 0)"
              title="Клиенты, сделавшие заказ сегодня"
            />
          </div>
        </el-col>
        <el-col :span="4">
          <div>
            <el-statistic
              :value="Number(infoRequests.sumPrice1c)"
              title="Общая сумма заказов 1C"
            />
          </div>
        </el-col>
        <el-col :span="4">
          <div>
            <el-statistic
              :value="Number(infoRequests.sumPrice || 0)"
              title="Общая сумма заказов по сайту"
            />
          </div>
        </el-col>
        <el-col :span="4">
          <div>
            <el-statistic
              :value="Number(infoRequests.recommendationSum || 0)"
              title="Сумма заказов по рекомендованным товарам"
            />
          </div>
        </el-col>
      </el-row>
      <h2>Заказы</h2>
      <div style="display:flex;gap:10px; align-items: center;">
        <el-button
          type="primary"
          :loading="reloading"
          icon="el-icon-refresh"
          @click="refresh"
        >
          Обновить
        </el-button>
        <el-select
          v-model="period"
          placeholder="Выбрать статистику за период"
          default-first-option
        >
          <el-option
            label="Год"
            value="year"
          />
          <el-option
            label="Месяц"
            value="month"
          />
          <el-option
            label="День недели"
            value="dayOfWeek"
          />
          <el-option
            label="Час"
            value="hour"
          />
        </el-select>
        <template v-if="period === 'dayOfWeek'">
          <el-date-picker
            v-model="dateFilter"
            type="daterange"
            range-separator=""
            start-placeholder="От даты"
            end-placeholder="до даты"
            :clearable="false"
            format="dd-MM-yyyy"
            value-format="yyyy-MM-dd"
          />
        </template>
        <div>
          <el-checkbox
            v-model="orderSum"
            label="Сумма заказов"
            @change="refresh"
          />
        </div>
      </div>
      <Bar
        v-if="period === 'year'"
        v-loading="ordersLoading"
        :chart-data="chartData"
        :chart-options="chartOptions"
      />
      <LineChartGenerator
        v-else
        v-loading="ordersLoading"
        :chart-data="chartData"
        :chart-options="chartOptions"
      />
    </div>
    <div style="margin: 20px 0">
      <el-button
        type="primary"
        :loading="clickhouseExporting"
        @click.prevent="clickhouseExportHandler"
      >
        Выгрузить данные в clickhouse
      </el-button>
    </div>
  </div>
</template>

<script>

// noinspection ES6UnusedImports
// eslint-disable-next-line
import Chart from 'chart.js/auto';
import { Bar,Line as LineChartGenerator } from 'vue-chartjs/legacy';
import { mapActions, mapState } from 'vuex';

import PageHeader from '@/components/page-header/page-header.vue';


export default {
  name: 'ViewAdminUsers',
  components: { PageHeader, LineChartGenerator, Bar },
  metaInfo: {
    title: 'Панель администрирования'
  },
  data() {
    return {
      interval: null,
      period: 'month',
      reloading: false,
      orderSum: false,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
      dateFilter: [],
      infoRequestsDate: (new Date()).toISOString().split('T')[0],
      infoRequests: null,
      infoRequestsLoading: false,
      clickhouseExporting: false,
    };
  },
  computed: {
    ...mapState('admin', ['healthcheckLoading', 'healthcheck', 'ordersLoading', 'orders']),
    chartData() {
      let labels = [];
      let data = this.orders.map(orderData => orderData.count);
      const weekdays = [
        'Понедельник',
        'Вторник',
        'Среда',
        'Четверг',
        'Пятница',
        'Суббота',
        'Воскресенье',
      ];
      if (this.period === 'hour') {
        /**
         *
         * @param {string} timeString
         * @param {number} startHour
         * @param {number} startMinute
         * @param {number} endHour
         * @param {number} endMinute
         */
        const timeFilter = (timeString, startHour, startMinute, endHour, endMinute) => {
          const time = new Date(timeString);
          return time.getHours() >= startHour
            && time.getMinutes() >= startMinute
            && time.getHours() <= endHour
            && time.getMinutes() <= endMinute;
        };
        labels = [
          '00:01-08:59',
          '09:00-10:00',
          '10:01-11:00',
          '11:01-12:00',
          '12:01-13:00',
          '13:01-14:00',
          '14:01-15:00',
          '15:01-16:00',
          '16:01-17:00',
          '17:01-18:00',
          '18:01-00:00',
        ];
        data = [
          this.orders.filter(orderData => timeFilter(orderData.hour, 0, 0, 8, 59)).reduce((sum, current) => sum + Number(current.count), 0),
          this.orders.filter(orderData => timeFilter(orderData.hour, 9, 0, 10, 0)).reduce((sum, current) => sum + Number(current.count), 0),
          this.orders.filter(orderData => timeFilter(orderData.hour, 10, 0, 11, 0)).reduce((sum, current) => sum + Number(current.count), 0),
          this.orders.filter(orderData => timeFilter(orderData.hour, 11, 0, 12, 0)).reduce((sum, current) => sum + Number(current.count), 0),
          this.orders.filter(orderData => timeFilter(orderData.hour, 12, 0, 13, 0)).reduce((sum, current) => sum + Number(current.count), 0),
          this.orders.filter(orderData => timeFilter(orderData.hour, 13, 0, 14, 0)).reduce((sum, current) => sum + Number(current.count), 0),
          this.orders.filter(orderData => timeFilter(orderData.hour, 14, 0, 15, 0)).reduce((sum, current) => sum + Number(current.count), 0),
          this.orders.filter(orderData => timeFilter(orderData.hour, 15, 0, 16, 0)).reduce((sum, current) => sum + Number(current.count), 0),
          this.orders.filter(orderData => timeFilter(orderData.hour, 16, 0, 17, 0)).reduce((sum, current) => sum + Number(current.count), 0),
          this.orders.filter(orderData => timeFilter(orderData.hour, 17, 0, 18, 0)).reduce((sum, current) => sum + Number(current.count), 0),
          this.orders.filter(orderData => timeFilter(orderData.hour, 18, 0, 23, 59)).reduce((sum, current) => sum + Number(current.count), 0),
        ];
      } else if (this.period === 'month') {
        labels = this.orders.map(orderData => new Date(orderData.month).toLocaleString('ru', {
          year: 'numeric',
          month: 'long',
        }));
      } else if (this.period === 'year') {
        labels = this.orders.map(orderData => new Date(orderData.year).toLocaleString('ru', {
          year: 'numeric',
        }));
      } else if (this.period === 'dayOfWeek') {
        labels = weekdays;
        data = [
          this.orders.find(orderData => Number(orderData.day) === 1)?.count,
          this.orders.find(orderData => Number(orderData.day) === 2)?.count,
          this.orders.find(orderData => Number(orderData.day) === 3)?.count,
          this.orders.find(orderData => Number(orderData.day) === 4)?.count,
          this.orders.find(orderData => Number(orderData.day) === 5)?.count,
          this.orders.find(orderData => Number(orderData.day) === 6)?.count,
          this.orders.find(orderData => Number(orderData.day) === 7)?.count,
        ];
      }
      return {
        labels,
        datasets: [
          {
            label: 'Заказы',
            backgroundColor: '#f87979',
            data,
          }
        ]
      };
    }
  },
  watch: {
    async period() {
      await this.getData();
    },
    async dateFilter() {
      await this.getData();
    },
  },
  mounted() {
    this.getHealthCheck().catch((e) => {
      this.$message.error('Ошибка загрузки статистики информации о статусе сайта');
      console.error('Ошибка загрузки статистики информации о статусе сайта', e)
    });

    this.getInfo();
    this.getData();



    /// https://t.me/c/2393504174/118/2308
    /// Также хотел тебя попросить убрать там с этой таблицы автообновление и поставить рядом с ней значок по которому будет ручное обновеление.
    // Сейчас если выбрать большой период то обновление происходит быстрее, чем приходит результат.
    // this.interval = setInterval(async () => {
    //   await this.getData();
    // }, 30000);
  },
  destroyed() {
    // clearInterval(this.interval);
  },
  methods: {
    ...mapActions('admin', ['getHealthCheck', 'getOrders', 'getInfoRequests', 'clickhouseExport']),
    getInfo() {
      this.infoRequestsLoading = true;
      this.getInfoRequests(this.infoRequestsDate)
        .then((infoRequests) => {
          this.infoRequests = infoRequests;
        })
        .catch((e) => {
          this.$message.error('Ошибка загрузки информации о заказах');
          console.error('Ошибка загрузки информации о заказах', e)
        })
        .finally(() => {
          this.infoRequestsLoading = false;
        });
    },
    async getData() {
      await this.getOrders({
        type: this.period,
        from: this.period === 'dayOfWeek' ? (this.dateFilter[0] || null) : undefined,
        to: this.period === 'dayOfWeek' ? (this.dateFilter[1] || null) : undefined,
        orderSum: this.orderSum
      }).catch((e) => {
        this.$message.error('Ошибка загрузки статистики по заказам');
        console.error('Ошибка загрузки статистики по заказам', e)
      });
    },
    async refresh() {
      this.reloading = true;
      await this.getData();
      this.reloading = false;
    },
    clickhouseExportHandler() {
      this.clickhouseExporting = true;
      this.clickhouseExport()
        .then(() => {
          this.$message.success('Данные экспортированы');
        })
      .catch((error) => {
        this.$message.error('Не удалось экспортировать данные: ' + error);
        console.error('Ошибка экспорта в clickhouse', error)
      }).finally(() => {
        this.clickhouseExporting = false;
      })
    }
  },
};
</script>

<style scoped lang="sass" src="../admin/access/admin.sass" />
