<template>
  <el-dialog
    title="Грузополучатель"
    :visible="visible"
    append-to-body
    width="400px"
    @close="handleClose"
  >
    <el-form>
      <el-form-item
        label="Юр.лицо"
        required
      >
        <el-select
          v-model="localItem.orgId"
          placeholder="Юр.лицо"
          style="width: 100%"
          no-data-text="Список юр. лиц пуст"
        >
          <el-option
            v-for="option in org"
            :key="option.value"
            :label="option.name"
            :value="option.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="Имя"
        required
      >
        <el-input
          v-model="localItem.name"
          style="width: 100%"
          placeholder="Имя"
        />
      </el-form-item>
      <el-form-item
        label="Телефон"
        required
      >
        <el-input
          v-model="localItem.phone"
          v-mask="'8##########'"
          style="width: 100%"
          placeholder="Телефон"
          minlength="11"
        />
      </el-form-item>
    </el-form>
    <el-alert
      v-if="isExisted"
      type="warning"
    >
      Этот грузополучатель уже добавлен
    </el-alert>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="handleClose">Отменить</el-button>
      <el-button
        type="primary"
        :disabled="!localItem.name || !localItem.phone || !localItem.orgId || isExisted"
        :loading="loading"
        @click="handleSave"
      >
        {{ localItem.id ? 'Изменить' : 'Добавить' }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: "ConsigneeEditDialog",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localItem: {},
    };
  },
  computed: {
    ...mapState('accounts', ['org']),
    ...mapState('contactsConsignees', ['consignees']),
    isExisted() {
      return this.consignees.findIndex((item) =>
        item.orgId === this.localItem.orgId &&
        item.name === this.localItem.name &&
        item.phone === this.localItem.phone &&
        !this.localItem.id
      ) !== -1;
    }
  },
  watch: {
    item: {
      handler(newVal) {
        this.localItem = structuredClone(newVal);
      },
      immediate: true,
    }
  },
  mounted() {
    this.GetOrg()
    if (this.localItem.orgId) {
      this.getAllConsignees(this.localItem.orgId)
    }
  },
  methods: {
    ...mapActions('accounts', ['GetOrg']),
    ...mapActions('contactsConsignees', ['getAllConsignees']),
    handleClose() {
      this.$emit("close");
    },
    handleSave() {
      this.$emit("save", this.localItem);
    },
  },
};
</script>
