<template>
  <div class="consignees">
    <div class="consignees__header">
      <h4>Грузополучатели</h4>
      <div class="consignees__controls">
        <el-button
          size="mini"
          type="primary"
          icon="el-icon-plus"
          @click="handleCreate"
        >
          Добавить
        </el-button>
        <el-select
          v-model="orgId"
          placeholder="Юр.лицо"
          size="small"
          no-data-text="Список юр. лиц пуст"
          clearable
          @change="handleSearch"
        >
          <el-option
            v-for="option in org"
            :key="option.value"
            :label="option.name"
            :value="option.id"
          />
        </el-select>
        <el-input
          v-model="searchQuery"
          placeholder="Поиск..."
          size="small"
          prefix-icon="el-icon-search"
          clearable
          style="width: 200px"
          @input="handleSearch"
        />
      </div>
    </div>

    <el-table
      v-loading="isLoading"
      :data="consignees"
    >
      <el-table-column
        v-if="isAdmin"
        prop="id"
        label="ID"
        width="50"
      />
      <el-table-column
        prop="name"
        label="Имя"
      />
      <el-table-column
        prop="phone"
        label="Телефон"
      />
      <el-table-column
        width="100"
        prop="orgId"
        label="Юр.лицо"
      >
        <template #default="{ row }">
          {{ org.find(o => o.id === row.orgId)?.name }}
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="Действия"
        width="120"
      >
        <template #default="{ row }">
          <div class="actions">
            <el-button
              size="mini"
              icon="el-icon-edit"
              @click="handleEdit(row)"
            />
            <el-button
              type="danger"
              size="mini"
              icon="el-icon-delete"
              @click="handleDelete(row)"
            />
          </div>
        </template>
      </el-table-column>
    </el-table>
    <consignee-edit-dialog
      v-if="editingItem"
      :visible="!!editingItem"
      :item="editingItem"
      :loading="isSending"
      @close="editingItem = null"
      @save="saveHandler"
    />
  </div>
</template>

<script>
import debounce from 'utils/debounce.js'
import { mapActions, mapGetters, mapState } from 'vuex'

import ConsigneeEditDialog from '@/components/ConsigneeEditDialog.vue'

export default {
  name: 'ConsigneesTable',
  components: {
    ConsigneeEditDialog
  },
  data() {
    return {
      searchQuery: '',
      orgId: null,
      editingItem: null,
      isSending: false,
    };
  },
  computed: {
    ...mapState('contactsConsignees', ['consignees', 'isLoading']),
    ...mapState('accounts', ['org']),
    ...mapGetters('auth', ['isAdmin']),
  },
  created() {
    this.loadConsignees();
    this.GetOrg();
  },
  methods: {
    ...mapActions('contactsConsignees', [
      'getAllConsignees',
      'deleteConsignee',
      'updateConsignee',
      'createConsignee',
      'searchConsignees'
    ]),
    ...mapActions('accounts', ['GetOrg']),
    async loadConsignees() {
      await this.getAllConsignees(this.orgId).catch((error) => {
        this.$message.error('Не удалось загрузить грузополучателей');
        console.error('Ошибка загрузки грузополучателей', error)
      })
    },

    handleEdit(row) {
      this.editingItem = structuredClone(row);
    },
    handleCreate() {
      this.editingItem = { name: '', phone: '' };
    },

    async handleDelete(row) {
      try {
        await this.$confirm('Вы уверены, что хотите удалить эту запись?', 'Внимание', {
          type: 'warning'
        });

        await this.deleteConsignee(row.id);
        this.$message.success('Запись успешно удалена');
        await this.loadConsignees();
      } catch (error) {
        if (error !== 'cancel') {
          this.$message.error('Ошибка при удалении записи');
        }
      }
    },
    async saveHandler(updatedItem) {
      this.isSending = true;
      if (updatedItem.id) {
        await this.updateConsignee({
          id: this.editingItem.id,
          data: {
            name: updatedItem.name,
            phone: updatedItem.phone,
            orgId: updatedItem.orgId,
          }
        }).then(() => {
          this.$message.success('Грузополучатель обновлен');
          this.editingItem = null;
          this.loadConsignees();
        }).catch(err => {
          console.error(err)
          this.$message.error('Ошибка изменения грузополучателя: ' + err.message);
        }).finally(() => {
          this.isSending = false
        });
      } else {
        await this.createConsignee({
          name: updatedItem.name,
          phone: updatedItem.phone,
          orgId: updatedItem.orgId,
        }).then(() => {
          this.$message.success('Грузополучатель добавлен');
          this.editingItem = null;
          this.loadConsignees();
        }).catch(err => {
          this.$message.error('Ошибка добавления грузополучателя: ' + err);
        }).finally(() => {
          this.isSending = false
        });
      }
    },

    handleSearch: debounce(async function() {
      try {
        if (this.searchQuery) {
          await this.searchConsignees({ search: this.searchQuery, orgId: this.orgId });
        } else {
          await this.loadConsignees();
        }
      } catch (error) {
        this.$message.error('Ошибка при поиске');
      }
    }, 300),
  }
};
</script>

<style scoped lang="sass">
.consignees
  &__header
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 20px
  &__controls
    display: flex
    gap: 10px

.actions
  display: flex
  gap: 5px
</style>
