<template>
  <div class="personal">
    <div class="personal__header">
      <div>
        <h4>Профиль пользователя</h4>
      </div>
      <div v-if="_role === 'superUser' || _role === 'admin'">
        <el-button
          size="small"
          type="primary"
          @click="$router.push('/dashboard/user-management')"
        >
          Управление пользователями
        </el-button>
      </div>
    </div>
    <el-form
      label-position="left"
      label-width="200px"
      :model="formLabelAlign"
    >
      <label class="input">
        <span class="input__label">Имя</span>
        <el-input
          v-model="formLabelAlign.name"
          class="input__input"
          size="small"
        />
      </label>
      <label class="input">
        <span class="input__label">Email</span>
        <el-input
          v-model="formLabelAlign.email"
          :disabled="true"
          size="small"
        />
      </label>
      <label class="input">
        <span class="input__label">Старый пароль</span>
        <el-input
          v-model="pass.old"
          show-password
          size="small"
        />
      </label>
      <label class="input">
        <span class="input__label">Новый пароль</span>
        <el-input
          v-model="pass.new"
          show-password
          size="small"
        />
      </label>

      <div style="display: flex; align-items: center;justify-content: flex-end">
        <el-button
          size="small"
          type="primary"
          @click="updateName"
        >
          Сохранить
        </el-button>
      </div>

    </el-form>

    <consignees-table />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import ConsigneesTable from "@/views/contacts/ConsigneesTable.vue";

export default {
  name: "ViewPersonal",
  components: { ConsigneesTable },
  metaInfo: {
    title: "Профиль",
  },
  data() {
    return {
      labelPosition: "right",
      formLabelAlign: {
        name: "",
        email: "",
      },
      pass: {
        old: "",
        new: "",
      },
      error: "",
    };
  },
  computed: {
    ...mapGetters("auth", ["_role"]),
    ...mapState("accounts", ["name", "email"]),
  },
  watch: {
    name(val) {
      this.formLabelAlign.name = val;
    },
    email(val) {
      this.formLabelAlign.email = val;
    },
  },
  created() {
    this.formLabelAlign.name = this.name || "";
    this.formLabelAlign.email = this.email || "";
  },
  methods: {
    ...mapActions("accounts", ["SetPostName", "SetPostPass"]),
    ...mapActions("auth", ["AuthLogout"]),
    updateName() {
      const changedName = this.formLabelAlign.name !== this.name;
      const changedPassword = this.pass.old && this.pass.new;

      this.error = "";
      if (changedPassword && this.pass.new.length < 6) {
        this.error = "Пароль не может быть короче 6 символов";
        return;
      }

      try {
        if (changedName) this.SetPostName(this.formLabelAlign.name);
        if (changedPassword) {
          this.SetPostPass({ old: this.pass.old, new: this.pass.new }).then(
            () => {
              this.logout();
            }
          );
        }
      } catch (e) {
        this.$message.error(e.message || e);
        return;
      }
      if (changedName && changedPassword)
        this.$message.success("Данные сохранены");
      else if (changedName) this.$message.success("Имя обновлено");
      else if (changedPassword) this.$message.success("Пароль обновлен");
      if (changedPassword) {
        this.pass.new = "";
        this.pass.old = "";
      }
    },
    logout() {
      this.AuthLogout();
      this.$router.push("/auth/login");
    },
  },
};
</script>

<style scoped lang="sass">
.personal
  padding: 20px 200px
  max-width: 1100px
  margin: 0 auto
  @media (max-width: 1300px)
    padding: 20px
  &__header
    display: flex
    justify-content: space-between
    align-items: center
.input
  display: flex
  flex-flow: column
  margin-bottom: 10px
  @media (min-width: 900px)
    flex-flow: nowrap
    margin-bottom: 25px
  &__label
    font-size: 14px
    color: #606266
    margin-bottom: 3px
    @media (min-width: 900px)
      width: 200px
      line-height: 32px
      margin-bottom: 0
</style>
