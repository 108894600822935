<template>
  <div class="filters">
    <div class="filter">
      <span class="filter__title">Наличие</span>
      <el-checkbox v-model="outOfStockComp">
        Товары не в наличии
      </el-checkbox>
    </div>
    <div class="filter">
      <span class="filter__title">Интересные предложения</span>
      <el-checkbox v-model="groupsOnly">
        <span class="promo">Выгодная цена</span>
      </el-checkbox>
      <el-checkbox
        v-model="fastDeliveryValue"
        :disabled="!isFastDeliveryAvailable"
      >
        Быстрая доставка

        <el-popover
          placement="top"
          trigger="hover"
        >
          При отправке заказа до 11:00 — доставка сегодня до 18:00.<br>
          Доступно со вторника по пятницу,<br>
          в понедельник, субботу и воскресенье — только стандартная доставка.
          <template #reference>
            <i class="el-icon-info" />
          </template>
        </el-popover>
      </el-checkbox>
      <el-checkbox
        v-model="expressDeliveryValue"
        :disabled="!isExpressDeliveryAvailable"
      >
        Экспресс доставка

        <el-popover
          placement="top"
          trigger="hover"
        >
          интервалы экспресс-доставки:<br>
          с 10:00 до 12:00 - экспресс-доставка до 14:00<br>
          с 12:00 до 14:00 - экспресс-доставка до 16:00<br>
          с 14:00 до 16:00 - экспресс-доставка до 18:00<br>
          с 16:00 до 09:59 следующего дня - доставка - до 12:00 следующего дня
          <template #reference>
            <i class="el-icon-info" />
          </template>
        </el-popover>
      </el-checkbox>
    </div>
    <div
      v-for="(filter, i) in filters"
      :key="`filters---${i}`"
    >
      <component
        :is="components[filter.type]"
        :filter="filter"
      />
    </div>

    <div
      v-if="filters.length"
      class="filters__buttons"
    >
      <el-button
        type="primary"
        class="set-filters"
        @click="filterOut"
      >
        Применить {{ applyFiltersCount > 9 ? '(9+)' : applyFiltersCount !== 0 ? `(${applyFiltersCount})` : '' }}
      </el-button>
      <el-button
        class="set-filters"
        size="small"
        @click="resetFilters"
      >
        Сбросить
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import filterCheckbox from './filters/filterCheckbox.vue';
import filterRange from './filters/filterRange.vue';

const components = {
  checkbox: 'filterCheckbox',
  range: 'filterRange'
};

export default {
  name: 'ProductsFilters',
  components: { filterCheckbox, filterRange },
  data() {
    return {
      components,
      selectedFilters: [],
    };
  },
  computed: {
    ...mapState(
      'products',
      [
        'filters', 'changedFilters', 'filterChange', 'outOfStock',
        'noFilters', 'onlyPromoActions', 'onlyGroups', 'fastDeliveryFilter', 'expressDeliveryFilter'
      ]
    ),
    ...mapGetters('basket', ['isFastDeliveryAvailable', 'isExpressDeliveryAvailable']),
    outOfStockComp: {
      set(val) {
        this.SET_OUT_OF_STOCK(val);
        this.filterOut();
      },
      get() {
        return this.outOfStock;
      }
    },
    fastDeliveryValue: {
      set(val) {
        this.SET_FAST_DELIVERY_FILTER(val);
        this.filterOut();
      },
      get() {
        return this.isFastDeliveryAvailable && this.fastDeliveryFilter;
      }
    },
    expressDeliveryValue: {
      set(val) {
        this.SET_EXPRESS_DELIVERY_FILTER(val);
        this.filterOut();
      },
      get() {
        return this.isExpressDeliveryAvailable && this.expressDeliveryFilter;
      }
    },
    groupsOnly: {
      set(val) {
        this.SET_ONLY_GROUPS(val);
        this.filterOut();
      },
      get() {
        return this.onlyGroups;
      }
    },
    queryToApply() {
      const params = this.changedFilters.reduce((preview, item) => ({
        ...preview,
        [item.name]: item.value ? item.value : `${item.gt}-${item.lt}`
      }), {});

      params.outOfStock = this.outOfStock ? 1 : undefined;
      params.onlyGroups = this.groupsOnly ? 1 : undefined;
      params.isFastDelivery = this.fastDeliveryFilter ? 1 : undefined;
      params.isExpressDelivery = this.expressDeliveryFilter ? 1 : undefined;
      return params;
    },
    applyFiltersCount() {
      return this.changedFilters.length;
    }
  },
  methods: {
    ...mapMutations(
      'products',
      [
        'RESET_PRODUCTS', 'SET_FILTER_CHANGE', 'SET_OUT_OF_STOCK',
        'REMOVE_CHANGED_FILTERS', 'SET_ONLY_GROUPS', 'SET_FAST_DELIVERY_FILTER', 'SET_EXPRESS_DELIVERY_FILTER'
      ]
    ),
    ...mapActions('products', ['GetProductsList']),
    filterOut() {
      this.RESET_PRODUCTS();
      this.$router.replace({
        path: '',
        query: this.queryToApply
      });
      this.SET_FILTER_CHANGE(false);
    },
    resetFilters() {
      this.$root.$emit('resetFilters');
      this.REMOVE_CHANGED_FILTERS();
      if (this.noFilters) {
        this.SET_FILTER_CHANGE(false);
      }
      if (JSON.stringify(this.$route.query) === JSON.stringify(this.queryToApply)) {
        return;
      }
      this.filterOut();
    }
  }
};
</script>

<style scoped lang="sass">
  .filters
    &__buttons
      width: 100%
      margin-top: 30px
      display: grid
      grid-gap: 10px
  .filter
    margin-top: 16px
    padding-top: 7px
    border-top: 1px solid #ebeef5
    &__title
      display: block
      font-size: 11px
      font-weight: bold
  .promo
    color: #3D8F5F
</style>
