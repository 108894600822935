import axiosInstance from 'api/axiosInstance.js'

const state = () => ({
  type: 'act',
  types: [],
  loading: false,
  documents: [],
  /** @type {'createdAt'|'type'|'status'} */
  sortField: 'createdAt',
  sortOrder: 'desc',
  statuses: {
    'IN WORK': 'В работе',
    'DONE': 'Обработан',
  },
  contracts: [],
  contractsLoading: false,
  nikolOrganizations: [],
  nikolOrganizationsLoading: false,
  reportLoading: false,
});

const mutations = {
  SET_DOCUMENTS(state, documents) {
    state.documents = documents;
  },
  SET_LOADING(state, loadingState) {
    state.loading = loadingState;
  },
  SET_TYPE(state, type) {
    state.type = type;
  },
  SET_TYPES(state, types) {
    state.types = types;
  },
  SET_SORT_FIELD(state, sortField) {
    state.sortField = sortField;
  },
  SET_SORT_ORDER(state, sortOrder) {
    state.sortOrder = sortOrder;
  },
  SET_REPORT_LOADING(state, loadingState) {
    state.reportLoading = loadingState;
  },
  SET_CONTRACTS(state, contracts) {
    state.contracts = contracts;
  },
  SET_CONTRACTS_LOADING(state, loadingState) {
    state.contractsLoading = loadingState;
  },
  SET_NIKOL_ORGANIZATIONS(state, nikolOrganizations) {
    state.nikolOrganizations = nikolOrganizations;
  },
  SET_NIKOL_ORGANIZATIONS_LOADING(state, loadingState) {
    state.nikolOrganizationsLoading = loadingState;
  },
};
const actions = {
  async createDocument(_, params) {
    return await axiosInstance.post("/api/documents", params);
  },
  async getDocuments({ state, commit }) {
    commit("SET_LOADING", true);
    await axiosInstance
      .get("/api/documents", {
        params: {
          type: state.type,
          max: 50,
          offset: 0,
          sortOrder: state.sortOrder,
          sortField: state.sortField,
        },
      })
      .then((response) => {
        commit("SET_DOCUMENTS", response);
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  async getDocumentsTypes({ commit }) {
    await axiosInstance.get("/api/documents/types").then((resp) => {
      commit("SET_TYPES", resp);
    });
  },
  /**
   * Получение договоров
   * @param commit
   * @param {number} orgId
   * @returns {Promise<void>}
   */
  async getContracts({ commit }, orgId) {
    commit("SET_CONTRACTS_LOADING", true);
    await axiosInstance.get("/api/contracts?orgId="+orgId).then((resp) => {
      commit("SET_CONTRACTS", resp);
    }).finally(() => {
      commit("SET_CONTRACTS_LOADING", false);
    });
  },
  /**
   * Получение организаций николь
   * @param commit
   * @returns {Promise<void>}
   */
  async getNikolOrganizations({ commit }) {
    commit("SET_NIKOL_ORGANIZATIONS_LOADING", true);
    await axiosInstance.get("/api/nikolOrganizations").then((resp) => {
      commit("SET_NIKOL_ORGANIZATIONS", resp);
    }).finally(() => {
      commit("SET_NIKOL_ORGANIZATIONS_LOADING", false);
    });
  },
  async getReport({ commit }, { type, from, to, userId, accountId }) {
    commit("SET_REPORT_LOADING", true);
    return await axiosInstance
      .get("/api/admin/data/reports", {
        params: {
          from,
          to,
          type,
          userId,
          accountId: accountId || undefined,
        },
        responseType: "blob",
      })
      .finally(() => {
        commit("SET_REPORT_LOADING", false);
      });
  },
};

const getters = {

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
