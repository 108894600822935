<template>
  <transition name="fade">
    <div
      v-if="needRefresh && !isUpdating"
      class="update"
    >
      <div class="update__title">
        Сайт был обновлен
      </div>
      <p>Пожалуйста, перезагрузите страницу для корректной работы</p>

      <button
        class="update__button"
        @click="updateServiceWorker"
      >
        Перезагрузить
      </button>
    </div>
  </transition>
</template>

<script>
import useRegisterSW from '@/utils/useRegisterSW';

export default {
  name: 'AppUpdate',
  mixins: [useRegisterSW],
  watch: {
    $route(to) {
      // Проверяем обновления при смене маршрута
      this.checkForUpdates();
      
      // Если есть обновление и мы еще не в процессе обновления,
      // применяем его автоматически при переходе
      if (this.needRefresh && !this.isUpdating) {
        this.updateAndNavigate(to.fullPath);
      }
    }
  }
};
</script>

<style scoped lang="sass">
.update
  position: fixed
  display: flex
  flex-direction: column
  bottom: 20px
  right: 20px
  background: #fff linear-gradient(45deg, #f2f3f6, #fafafc)
  border: 1px solid #d5d7de
  box-shadow: 0 9px 22px 0 rgba(0, 0, 50, .05)
  padding: 20px
  border-radius: 6px
  font-size: 14px
  z-index: 1000
  max-width: 350px
  &__title
    font-weight: bold
  &__button
    background: #e5e9f2
    color: #4b89b2
    padding: 10px
    cursor: pointer
    font-weight: bold
    border: 1px solid #d5d7de
    border-radius: 4px
    transition: background-color 0.2s ease
    &:hover
      background-color: #d3dce6

// Анимация появления и исчезновения
.fade-enter-active, .fade-leave-active
  transition: opacity 0.3s, transform 0.3s
.fade-enter, .fade-leave-to
  opacity: 0
  transform: translateY(10px)
</style>
