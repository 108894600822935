export default {
  name: 'useRegisterSW',
  data() {
    return {
      updateSW: null,
      offlineReady: false,
      needRefresh: false,
      registration: null,
      updateCheckInterval: null,
      isUpdating: false
    }
  },
  async mounted() {
    if (!this.isServiceWorkerSupported()) {
      return;
    }

    await this.setupServiceWorker();
    this.startRegularUpdateChecks();
  },
  beforeDestroy() {
    this.clearUpdateCheckInterval();
  },
  methods: {
    isServiceWorkerSupported() {
      return 'serviceWorker' in navigator;
    },
    async setupServiceWorker() {
      // Listen for service worker updates
      navigator.serviceWorker.addEventListener('controllerchange', this.handleControllerChange);

      // Check if there's already a controller (service worker)
      if (!navigator.serviceWorker.controller) {
        return;
      }

      // Get the registration
      this.registration = await navigator.serviceWorker.getRegistration();

      if (!this.registration) {
        return;
      }

      // Check for updates immediately
      this.registration.update();

      // Listen for state changes
      this.registration.addEventListener('updatefound', this.handleUpdateFound);
    },
    handleControllerChange() {
      // New service worker is activated, but page needs refresh
      this.needRefresh = true;
    },
    handleUpdateFound() {
      const newWorker = this.registration.installing;
      newWorker.addEventListener('statechange', () => {
        if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
          // New version available
          this.needRefresh = true;
        }
      });
    },
    updateServiceWorker() {
      // Reload the page to activate the new service worker
      window.location.reload();
    },
    // Update service worker and navigate to target path
    updateAndNavigate(targetPath) {
      // Prevent multiple calls
      if (this.isUpdating) {
        return;
      }

      this.isUpdating = true;

      // Reload the page to activate the new service worker
      window.location.href = targetPath;
    },

    startRegularUpdateChecks() {
      // Check for updates every minute
      this.updateCheckInterval = setInterval(this.checkForUpdates, 60 * 1000);

      // Also check immediately
      this.checkForUpdates();
    },

    clearUpdateCheckInterval() {
      if (this.updateCheckInterval) {
        clearInterval(this.updateCheckInterval);
        this.updateCheckInterval = null;
      }
    },

    checkForUpdates() {
      if (!this.isServiceWorkerSupported()) {
        return;
      }

      navigator.serviceWorker.getRegistration()
        .then(registration => {
          if (registration) {
            registration.update();
          }
        })
        .catch(error => {
          console.error('Error updating service worker:', error);
        });
    }
  }
}
